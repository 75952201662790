<template>
  <div class="page">
    <div class="flex c-gap-5 ai-center">
      <div class="bold">{{ $t('legion.history.title') }}</div>
    </div>
    <div class="m-t-10 responsive-box c-gap-10 r-gap-10">
      <div class="flex ai-center c-gap-8 black-box responsive-box-child">
        <div class="bold" style="width:130px;">{{ $t('legion.history.myPoints') }}:</div>
        <div class="bold">{{ Number(integral).toFixed(2) }}</div>
      </div>
      <div class="flex jc-between ai-center black-box responsive-box-child">
        <div class="flex c-gap-8">
          <div class="bold" style="width:130px;">{{ $t('legion.history.receivedMds') }}MDS:</div>
          <div class="bold">{{ Number(receiveIntegral).toFixed(2) }}</div>
        </div>
      </div>
    </div>
    <div class="m-t-10 section padding20 d-flex flex-column">
      <!-- Transaction 交易 -->
      <div class="flex-1" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)">
        <!-- <h1>Transaction</h1> -->
        <div class="flex jc-between flex-wrap">
          <div class="tabs flex tab-header-left">
            <div class="tab m-r-10 cursor" :class="{ active: tabCurrent === 1 }" @click="tabCurrent = 1">
              {{ $t('legion.history.tabPointAcquisition') }}
            </div>
            <div class="tab cursor" :class="{ active: tabCurrent === 2 }" @click="tabCurrent = 2">
              {{ $t('legion.history.tabMdsCollection') }}
            </div>
          </div>
          <div class="tab-header-right">
          </div>
        </div>
        <div v-if="tabCurrent == 1">
          <div class="m-l-15 m-t-20 bold fs-xl">{{ $t('legion.history.titleMdsCollectionRecord') }}</div>
          <el-timeline v-if="activityList[1].length" class="timeline-account">
            <el-timeline-item v-for="(item, i) in activityList[1]" :key="i" :timestamp="item.created_at" placement="top">
              <div class="white bold">
                {{ item.typeName }}
                <span class="m-l-3 fs-sm bold" style="color:#37B497;">+{{ Number(item.earnings).toFixed(2) }}</span>
              </div>
            </el-timeline-item>
          </el-timeline>
          <div v-else-if="!loading" class="d-flex ai-center jc-center" style="min-height: 500px">
            <img alt="norecord" src="../assets/public/norecord.png" />
          </div>
          <p v-if="meta[1].last - meta[1].current > 0" class="more">
            <span class="c-m hover" @click="pageFun(1, meta[1].current + 1)">{{ $t("legion.history.more") }}
              >></span>
          </p>
        </div>
        <div v-else-if="tabCurrent == 2">
          <div class="m-l-15 m-t-20 bold fs-xl">{{ $t('legion.history.titleMdsCollectionRecord') }}</div>
          <el-timeline v-if="activityList[2].length" class="timeline-account">
            <el-timeline-item v-for="(item, i) in activityList[2]" :key="i" :timestamp="item.created_at" placement="top">
              <div class="white bold">
                {{ $t('legion.history.typeMdsCollection') }}
                <span class="m-l-3 fs-sm bold" style="color:#37B497;">{{ Number(item.amount).toFixed(2) }}</span>
              </div>
            </el-timeline-item>
          </el-timeline>
          <div v-else-if="!loading" class="d-flex ai-center jc-center" style="min-height: 500px">
            <img alt="norecord" src="../assets/public/norecord.png" />
          </div>
          <p v-if="meta[2].last - meta[2].current > 0" class="more">
            <span class="c-m hover" @click="pageFun(2, meta[2].current + 1)">{{ $t("legion.history.more") }}
              >></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      tabCurrent: 1,
      integral: 0,
      receiveIntegral: 0,
      activityList: {
        1: [],
        2: []
      },
      meta: {
        1: {
          current: 1,
          last: 1,
          total: 0,
        },
        2: {
          current: 1,
          last: 1,
          total: 0,
        },
      },
    };
  },
  computed: {
    ...mapState({
      sets: (state) => state.sets,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.pageFun(1, 1);
      this.pageFun(2, 1);
    }, 200);
  },
  methods: {
    pageFun(tab, page = 1) {
      this.loading = true;
      this.$axios
        .get(
          this.$api.integralActivity,
          { page, pageSize: 10, type: tab },
          { Serverid: this.$store.state.serverid }
        )
        .then((res) => {
          res.data.list.map((item) => {
            if (item.type == 1) {
              item.typeName = this.$t('legion.history.type.1')
            } else if (item.type == 2) {
              item.typeName = this.$t('legion.history.type.2')
            }
          })
          this.activityList[tab] = this.activityList[tab].concat(res.data.list);
          this.integral = Number(res.data.integral)
          this.receiveIntegral = Number(res.data.receive_integral)
          this.meta[tab] = {
            current: Number(res.data.page),
            last: res.data.totalPage || 1,
            total: res.data.total,
          };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};

</script>


<style lang="scss" scoped>
.section {
  width: 100%;
  min-height: 500px;
  background: rgba(41, 43, 57, 0.5);
  border: 1px solid #4c5158;
  border-radius: 4px;

  .tab {
    border: 1px solid #999;
    border-radius: 4px;
    padding: 10px 20px;
    text-align: center;
  }

  .active {
    background: #317f6e;
    border: none;
  }

  .timeline-account {
    padding: 20px;
    .box {
      p {
        color: #fff;
        margin-bottom: 5px;
      }
      a {
        color: #999;
        text-overflow: ellipsis;
        display: -webkit-box;
        word-break: break-all;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-decoration: underline;
      }
    }
  }
}

.black-box {
  background: rgba(41, 43, 57, 0.5);
  border: 1px solid #4c5158;
  border-radius: 4px;
  padding: 14px;
  height: 78px;
}

.more {
  align-self: flex-end;
}


.responsive-box {
  display: flex;
  justify-content: space-between;

  .responsive-box-child {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {

  .responsive-box {
    display: flex;
    flex-direction: column;

    .responsive-box-child {
      width: 100%;
    }
  }

}

</style>